<template>
<v-app>
    <SingleAppBar title="ค่าน้ำหนัก" :backward="true" name="healthrecord"/>
    <v-main class="natural lighten-4 fill-height">
      <v-container class="pa-0 ma-0">
         <EmptyVitalState v-if="items.length === 0"/>
          <v-list v-else two-line >
              <template v-for="(item,index) in items">
              <v-list-item :key="index">
                  <v-list-item-content>
                      <v-list-item-title class="text-h5"><v-icon>mdi-weight-kilogram</v-icon>{{item.weight}}<span class="text-caption">kg.</span> </v-list-item-title>
                      <v-list-item-subtitle v-if="item.remark"><span class="text-caption">หมายเหตุ </span>{{item.remark}}</v-list-item-subtitle>
                 </v-list-item-content>
               <v-list-item-action>
                    <v-list-item-action-text><v-icon color="natural darken-2">mdi-calendar-clock</v-icon> {{ $dayjs(new Date(item.device_timestamp*1000) ).local($i18n.locale).format('DD MMMM YYYY HH:mm')  }}</v-list-item-action-text>
               </v-list-item-action>
              </v-list-item>
              <v-divider :key="'d'+index+1"/>
              </template>
          </v-list>
      </v-container>
       <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
      </v-main
    >
</v-app>
</template>

<script>
import SingleAppBar from "@/components/common/SingleAppBar";
import EmptyVitalState from "@/components/healthrecord/EmptyVitalState";
import { getVSign } from "@/api/cms"
// import {getVitalSignHistory} from "@/api/"
//import ListCard from "@/components/inbox/ListCard";

export default {
  name: "BPMHistory",
  components: {
    SingleAppBar,
    EmptyVitalState
    //ListCard
  },
  data() {
    return {
      state: false,
      items: [],
      loading: false,

    }
  },
  methods: {
    async fetchData(){
    try {
      this.loading = true
      const bpm = await getVSign()
    if(bpm.data){
      this.items = bpm.data.body_component
    }
    } catch (error) {
      console.log(error)
    }finally{
      this.loading = false
    }
    }
  },
  
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  }
};
</script>

<style lang="scss" scoped>

</style>